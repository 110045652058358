<template>
  <div class="box">
    <div class="result">
      <div class="header">
        <div class="title">亲爱的用户</div>
        <div class="title">恭喜您已匹配到 {{ param.productCount }} 款产品</div>
      </div>
      <div class="price-box">
        <div class="price-title">预计可贷款总额(元)</div>
        <div class="price">￥{{ param.allQuota | changePrice2money() }}</div>
      </div>
      <!-- <div class="company-box">
        <div class="title">企业信息</div>
        <div class="company-info">

        </div>
      </div> -->
      <div class="end">
        <div class="end-title">企业信息</div>
        <table cellpadding="0" cellspacing="0" class="table">
          <tr>
            <td
              style="
                color: #333333;
                border-right: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
              "
            >
              统一社会信用代码
            </td>
            <td style="border-bottom: 1px solid #ffffff">
              {{ param.taxAccount }}
            </td>
          </tr>
          <tr>
            <td
              style="
                color: #333333;
                border-right: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
              "
            >
              法定代表人姓名
            </td>
            <td style="border-bottom: 1px solid #ffffff">
              {{ param.corporation }}
            </td>
          </tr>
          <tr>
            <td style="color: #333333; border-right: 1px solid #ffffff">
              企业名称
            </td>
            <td>{{ param.name }}</td>
          </tr>
        </table>

        <!-- 弹窗 -->
        <van-dialog v-model="showModal" :show-confirm-button="false">
          <div class="content" v-if="showCode">
            <div class="title">
              验证码已发送至法人手机， 用于实名验证，为安全起见，请勿泄露
            </div>
            <van-password-input
              :value="smsCode"
              :mask="false"
              gutter="6px"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
            />
            <van-number-keyboard
              v-model="smsCode"
              :show="showKeyboard"
              @blur="showKeyboard = false"
            />
            <div class="tip" v-if="count != 60">
              {{ count }}后可重新获取验证码
            </div>
            <div class="tip" v-else @click="reGetCode">重新获取验证码</div>
            <div class="queren" @click="confirm">确认</div>
            <div class="quxiao" @click="cancelHandle">取消</div>
          </div>
          <div class="content" v-else>
            <div class="header">
              <img src="../../img/cg.png" alt="" />
              <div>您已银税实名认证</div>
            </div>
            <div class="queren" @click="naxtPage">立即精准测额</div>
            <div class="quxiao" @click="cancelHandle">取消</div>
          </div>
        </van-dialog>
      </div>
      <img src="@/img/logo.png" class="logo" />
      <van-button class="showModal" @click="showModel"
        >银税实名认证，精准测额</van-button
      >
    </div>
  </div>
</template>
<script>
import url from "@/service/url-config.js";
export default {
  data() {
    return {
      showModal: false,
      showKeyboard: false,
      smsCode: "",
      showCode: true,
      count: 60,
      param: {
        isAuth: this.$route.query.isAuth || "", // 1,
        productCount: this.$route.query.productCount || "", // 6,
        allQuota: this.$route.query.allQuota || "", // 1907199.96,
        taxAccount: this.$route.query.taxAccount || "", // "91410105MA41GL7W62",
        corporation: this.$route.query.corporation || "", //"蒋怡然",
        name: this.$route.query.name || "", //"河南卓沣融通商务信息咨询有限公司",
        loanPredictionId: this.$route.query.loanPredictionId || "", //10380,
      }, //路由传参
    };
  },
  filters: {
    //数字转金额
    changePrice2money(value) {
      if (value === 0) {
        return parseFloat(value).toFixed(2);
      }
      if (value != "") {
        var num = "";
        value += ""; //转化成字符串
        value = parseFloat(value.replace(/,/g, "")).toFixed(2); //若需要其他小数精度，可将2改成变量
        if (value.indexOf(".") == -1) {
          num = value.replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return s + ",";
          });
        } else {
          num = value.replace(/(\d)(?=(\d{3})+\.)/g, function (s) {
            return s + ",";
          });
        }
      } else {
        num = "";
      }
      return num;
    },
  },
  methods: {
    //显示弹窗
    // isAuth=1时前往精准匹配
    showModel() {
      const { isAuth } = this.param;
      if (isAuth == 0) {
        this.showCode = true;
        this.reGetCode();
      } else {
        this.showCode = false;
      }
      this.showModal = true;
    },
    //60s⏲
    jiShi() {
      this.count = 59;
      const timer = setInterval(() => {
        let time = this.count;
        this.count = time - 1;
        if (this.count < 1) {
          clearInterval(timer);
          this.count = 60;
        }
      }, 1000);
    },
    //重新获取验证码
    async reGetCode() {
      this.jiShi();
      const { code, msg } = await this.$http.post(url.getLoginCode3, {
        loanPredictionId: this.param.loanPredictionId,
      });
      if (code != 200) console.log(msg);
    },
    //取消弹窗事件
    cancelHandle() {
      this.showModal = false;
      this.smsCode = "";
    },
    //确认验证码
    async confirm() {
      if (this.smsCode && (this.smsCode + "").length == 6) {
        const { loanPredictionId } = this.param;
        const { code } = await this.$http.post(url.getLogin4, {
          code: this.smsCode,
          loanPredictionId,
        });
        if (code == 200) {
          this.param.isAuth = 1;
          this.showCode = false;
        }
      } else {
        this.$toast("请输入六位验证码");
      }
    },
    // 实名后去再匹配页面
    naxtPage() {
      this.$router.replace({
        path: "match2",
        query: {
          loanPredictionId: this.param.loanPredictionId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  background-image: url("../../img/match/result-bg.png");
  background-size: 100% 100%;
}
.result {
  padding: 46px 15px;
  height: calc(100vh - 92px);
}

.header {
  padding-top: 70px;
  .title {
    font-size: 21px;
    font-family: HYXingKaiJ;
    font-weight: bold;
    color: #000000;
  }
}
.price-box {
  width: 347px;
  height: 159px;
  background-image: url("../../img/match/result-box.png");
  background-size: 100% 100%;
  text-align: center;
  margin-top: 20px;
  .price-title {
    font-size: 13px;
    color: #ffffff;
    padding-top: 47px;
  }
  .price {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    padding-top: 20px;
  }
}

.middle {
  height: 20px;
  background: #fff;
  display: flex;
  align-items: center;

  .left,
  .right {
    height: 20px;
    width: 10px;
    border-radius: 0px 10px 10px 0;
    background: #f4f5f9;
  }

  .right {
    border-radius: 10px 0px 0px 10px;
  }

  .center {
    flex: 1;
    display: flex;
    justify-content: space-around;

    .diandian {
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background: #f4f5f9;
    }
  }
}

.end {
  background: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: 6px;
  .end-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: left;
    padding-left: 13px;
  }
  .table {
    background: #f1f5fb;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 16px;
    width: 305px;
    color: #4675f8;
    font-size: 12px;

    tr {
      height: 30px;
      text-align: left;

      td {
        padding: 12px;
      }
    }
  }

  .content {
    height: 60vh;
    padding: 25px 0 0;

    .van-password-input__security li {
      border-bottom: 2px solid rgba(221, 221, 221, 1);
      font-size: 36px;
      color: #333333;
    }

    .title {
      width: 90%;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 26px;
    }

    .tip {
      width: 90%;
      margin: 0 auto;
      text-align: right;
      margin-top: 14px;
    }

    .queren,
    .quxiao {
      width: 90%;
      height: 42px;
      margin: 0 auto;
      line-height: 42px;
      border-radius: 2px;
      font-size: 14px;
    }

    .queren {
      margin-top: 80px;
      font-weight: 500;
      background: #0084ff;
      color: #fff;
      border-radius: 2px;
    }

    .quxiao {
      color: #666666;
    }

    .header {
      img {
        width: 70px;
        height: 70px;
      }

      div {
        margin-top: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        font-size: 18px;
      }
    }
  }
}
.logo {
  width: 150px;
  height: 37px;
  display: block;
  margin: 0 auto;
  margin-top: 33px;
}
.showModal {
  width: 325px;
  height: 44px;
  line-height: 44px;
  background: #113fc4;
  border-radius: 22px;
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
}
</style>